import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/logs", config);
};

const getExceptions = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/logs/exceptions", config);
};

const getModels = async (config=null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/logs/models", config);
};


export default {
    getAll,
    getModels,
    getExceptions
};
